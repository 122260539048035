import "./ChatInsertCoin.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import { ChatViewType } from "components/Chat/ChatViewType";
import StatelessButton from "components/forms/StatelessButton/StatelessButton";
import { useNavigate } from "react-router-dom";
import { authStore } from "auth/AuthStore";

export default function ChatInsertCoin() {
    const navigate = useNavigate();
    const { userProfile } = authStore();

    const viewType = ChatViewType.InsertCoin;

    return (
        <ChatBubble viewType={viewType}>
            <ChatPreContainer>
                <ChatPre viewType={viewType}>
                    <ChatText
                        text="INSERT COIN"
                    />
                </ChatPre>
            </ChatPreContainer>
            <img className="ChatInsertCoin-img" src="insert_coin.webp" alt="Retro arcade machine displaying, insert coin, on the screen"></img>
            <ChatPreContainer>
                <ChatPre viewType={ChatViewType.Scene}>
                    <ChatText text="We'll keep your action safe while you top up your Play Tokens." />
                </ChatPre>
            </ChatPreContainer>
            <ChatPreContainer>
                <ChatPre viewType={ChatViewType.Scene}>
                    <ChatText text={`Current balance: ${userProfile?.tokenBalance}`} />
                </ChatPre>
            </ChatPreContainer>
            <StatelessButton
                text="🛒 Top Up Tokens"
                onPressed={() => navigate("/account")}
                autoFocus={true}
                tip="Top Up Play Tokens Now"
            />
        </ChatBubble >
    );
}
