import { Fragment, useEffect, useState } from "react";
import "./OneOffPurchaseTable.css";
import { authStore } from "auth/AuthStore";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import Loading from "components/Loading/Loading";
import { WebConfig } from "WebConfig";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

let clientSecretTimer: any = undefined;

export default function OneOffPurchaseTable() {
    const { userProfile } = authStore();
    const [stripeClientSecret, setStripeClientSecret] = useState<string | undefined>();
    const { loginWithRedirect } = useAuth0();

    const encodeBase64Url = (input: string) => {
        return window.btoa(input)
            .replace(/\+/g, "-") // Replace + with -
            .replace(/\//g, "_") // Replace / with _
            .replace(/=+$/, ""); // Remove = padding
    };

    useEffect(() => {
        const updateStripeClientSecret = async () => {
            try {
                const clientSecret = await UserProfileRetriever.getUserStripeClientSecret();
                setStripeClientSecret(clientSecret);
                console.log("Updated Stripe client secret");
            } catch (e) {
                if (e instanceof AuthError) {
                    loginWithRedirect(
                        { appState: { returnTo: window.location.pathname } });
                } else {
                    console.error(e);
                }
            }
        };

        updateStripeClientSecret();
        clearInterval(clientSecretTimer);
        // The Stripe client secret is valid for 30 minutes, and checkout must be completed
        // before its expiry. Renewing it every 10 minutes means there's at least 20 to complete
        // checkout once a Pay button is clicked.
        // https://docs.stripe.com/payments/checkout/pricing-table#customer-client-secret-expiration
        const tenMinutesInMs = 600000;
        clientSecretTimer = setInterval(updateStripeClientSecret, tenMinutesInMs);

        // Clean up the interval on component unmount
        return () => {
            clearInterval(clientSecretTimer);
            console.log("Cleanup client secret updating on unmount");
        };
    }, [loginWithRedirect]);

    return (
        <Fragment>
            {(userProfile && stripeClientSecret) &&
                <div className="OneOffPurchaseTable">
                    <div className="OneOffPurchaseTable-under OneOffPurchaseTable-tl"></div>
                    <div className="OneOffPurchaseTable-over OneOffPurchaseTable-tl"></div>
                    <div className="OneOffPurchaseTable-under OneOffPurchaseTable-tr"></div>
                    <div className="OneOffPurchaseTable-over OneOffPurchaseTable-tr"></div>
                    <div className="OneOffPurchaseTable-under OneOffPurchaseTable-bl"></div>
                    <div className="OneOffPurchaseTable-over OneOffPurchaseTable-bl"></div>
                    <div className="OneOffPurchaseTable-under OneOffPurchaseTable-br"></div>
                    <div className="OneOffPurchaseTable-over OneOffPurchaseTable-br"></div>
                    <div className="OneOffPurchaseTable-inner">
                        <stripe-pricing-table
                            publishable-key={WebConfig.STRIPE_PUBLISHABLE_KEY}
                            pricing-table-id={WebConfig.STRIPE_ONE_OFF_PRICING_TABLE_ID}
                            customer-session-client-secret={stripeClientSecret}
                            client-reference-id={encodeBase64Url(userProfile.id)}
                        >
                        </stripe-pricing-table>
                    </div>
                </div>
            }
            {(!(userProfile && stripeClientSecret)) &&
                <div className="OneOffPurchaseTable-loading">
                    <Loading />
                </div>
            }
        </Fragment>
    );
}
