/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Sidebar.css";
import { pushRotate as Menu, Props as BurgerProps } from "react-burger-menu";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { WebConfig } from "WebConfig";
import ContentLink from "components/ContentLink/ContentLink";
import { authStore } from "auth/AuthStore";

export default function Sidebar(props: BurgerProps) {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isOpen, setOpen] = useState(false);
  const { userProfile } = authStore();

  const isUserAuthed = isAuthenticated || WebConfig.UNTETHERED === "1";

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    <Menu isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      width={"80%"}
      pageWrapId={props.pageWrapId}
      outerContainerId={props.outerContainerId}
    >
      <h2 className="Sidebar-endless-title" onClick={
        () => {
          closeSideBar();
          navigate("/");
        }}
        aria-details="Navigates to play"
      >
        <img className="Sidebar-endless-logo" src="EndessGPT_Logo_50px.png" alt="EndlessGPT logo" />
        <span>EndlessGPT</span>
        <img className="Sidebar-chat-thumb" src="chat-thumbnail.png" alt="Return to game thumbnail" />
      </h2>
      {(!isUserAuthed) &&
        <a className="SideBar-login" onClick={() => { loginWithRedirect(); }}>
          <i className="fa fa-fw fa-sign-in" />
          <span>Log in</span>
        </a>
      }
      {isUserAuthed &&
        <Link to="/account" onClick={closeSideBar} className="Sidebar-account">
          <i className="fa fa-fw fa-user" />
          <span className="Sidebar-account-span">Account</span>
          {userProfile &&
            <Fragment>
              <br /><span className="Sidebar-balance">Token Balance:<span className="Sidebar-tokens">{userProfile.tokenBalance}</span></span>
            </Fragment>
          }
        </Link>
      }
      <Link to="/" onClick={closeSideBar}>
        <i className="fa-regular fa-square-caret-right"></i>
        <span>Continue</span>
      </Link>
      <Link to="/new-game" onClick={closeSideBar}>
        <i className="fa-solid fa-wand-sparkles"></i>
        <span>New Game</span>
      </Link>
      {(isUserAuthed && WebConfig.IS_OUT_OF_SERVICE !== "1") &&
        <Link to="/load" onClick={closeSideBar}>
          <i className="fa-solid fa-upload"></i>
          <span>Load Game</span>
        </Link>
      }
      <Link to="/game-manual" onClick={closeSideBar}>
        <i className="fa-solid fa-book-open"></i>
        <span>Game Manual</span>
      </Link>
      <Link to="/faq" onClick={closeSideBar}>
        <i className="fa-regular fa-circle-question"></i>
        <span>F.A.Q.</span>
      </Link>
      <span className="Sidebar-policy"><ContentLink text="Privacy Policy" path="/privacy-policy" /> | <ContentLink text="Terms of Use" path="/terms-of-use" /> | <ContentLink text="Contact Us" path="/contact" /></span>
    </Menu>
  );
}
