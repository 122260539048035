import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ApiConfig } from "ApiConfig";
import { WebConfig } from "WebConfig";
import { Fragment } from "react";

export default function Auth0ProviderWithHistory({ children }) {
  const domain = WebConfig.AUTH0_DOMAIN;
  const clientId = WebConfig.AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Fragment>
      {WebConfig.UNTETHERED !== "1" &&
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            audience: ApiConfig.audience,
            redirect_uri: window.location.origin
          }}
          onRedirectCallback={onRedirectCallback}
        >
          {children}
        </Auth0Provider>
      }
      {WebConfig.UNTETHERED === "1" &&
        <Fragment>{children}</Fragment>
      }
    </Fragment >
  );
}
