export enum ChatViewType {
    Scene = "scene",
    Action = "action",
    Problem = "problem",
    Input = "input",
    Loadable = "loadable",
    NewGame = "new-game",
    Cover = "cover",
    SuggestedActions = "suggestions",
    Example = "example",
    InsertCoin = "insert-coin",
}
