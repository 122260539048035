// Maintaining some words here, which users may see, to make localization a bit easier later.
export class ErrorCopy {
    public static readonly WontCountLatestMove =
        "No tokens were deducted. You can copy your action and try sending it again or enter a new action.";
    public static AccountActionWarning = "automatic actions or investigations by EndlessGPT staff taking place. Action may be taken on your account, including but not limited to enabling action screening, which will make your possible actions more restricted, or banning your account. No refunds for remaining tokens will be issued in such cases.";
    public static readonly UnknownServerError = "Sorry, something went wrong.";
    public static readonly UnknownServerPlayError = this.UnknownServerError + " " + ErrorCopy.WontCountLatestMove;
    public static readonly Timedout = ErrorCopy.UnknownServerPlayError;
    public static readonly ActionViolation =
        "Your turn was blocked by action screening. EndlessGPT is intended to be \"safe for work\". Refrain from any explicit or ultraviolent actions. No tokens were deducted. All violations are flagged and repeated violations may result in action on your account, including but not limited to investigation by EndlessGPT staff and banning your account. No refunds will be issued in such cases for remaining tokens.";
    public static readonly SceneViolation =
        "Your turn resulted in a scene which triggered our content moderation filters. EndlessGPT is intended to be \"safe for work\". Please refrain from any actions which may lead to explicit or ultraviolent scenes. Tokens were deducted, since it still costs to process such an action. Frequent violations may result in " + ErrorCopy.AccountActionWarning;
    public static GetProfileError = "Failed to retrieve user profile.";
    public static FormValidationError = "Form validation failed.";
    public static SubscribeUnauthedError = "Error submitting unauthed mailing list subscription.";
    public static SubmitContactTicketError = "Error submitting contact us form.";
    public static SubmitContactUnauthed = "Unauthorized when submitting contact ticket.";
    public static PaymentStatusUnavailable = "Sorry, we couldn't get the status of your payment. Processing of your payment may be delayed. Please retry refreshing after a while. Alternatively, you may open a support ticket regarding your payment, and we will look after you.";
    public static KeepTurnSafeSignIn = "We'll keep your action safe while you sign in";
    public static PlayPositionOutdated = "Pardon the interruption, but it seems you have a more up-to-date autosave. We'll load it now.";


    // Validation messages
    public static CaptchaFailed = "Captcha roll is required.";
    public static EmailRequired = "Email address is required.";
    public static EmailNotEmailAddress = "Email address is invalid.";
    public static SubjectRequiredEnding = " is required.";
}
