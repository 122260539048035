import { IUserProfile } from "backend-models/services/users/users-api-models/IUserProfile";
import { create } from "zustand";

interface IConditionsModalState {
    isOpen: boolean;
    promptToAcceptConditionsIfNeeded: (
        userProfile: IUserProfile,
        beforePromptCallback: () => void,
        acceptedCallback: () => void,
    ) => void;
    onAccepted: () => void,
    closeModal: () => void;
}

export const useConditionsModalStore = create<IConditionsModalState>((set) => {

    return {
        isOpen: false,
        promptToAcceptConditionsIfNeeded: (userProfile, beforePromptCallback, acceptedCallback) =>
            set(() => {
                if ((!userProfile) || userProfile.acceptedConditionsUtcIso8601) {
                    acceptedCallback();
                    return {};
                } else {
                    beforePromptCallback();
                    return {
                        isOpen: true,
                        onAccepted: acceptedCallback,
                    };
                }
            }),
        onAccepted: () => { },
        closeModal: () => set(() => {
            return {
                isOpen: false,
            };
        }),
    };
});

