import { Fragment, useEffect, useState } from "react";
import "./PurchaseHistory.css";
import { authStore } from "auth/AuthStore";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import Loading from "components/Loading/Loading";
import { IPurchaseView } from "backend-models/services/payments/payment-api-models/IPurchaseView";
import * as R from "ramda";

interface IPurchaseHistoryItem {
    pv: IPurchaseView;
    localTime: string;
    amount: string;
}

export default function PurchaseHistory() {
    const { userProfile } = authStore();
    const [purchases, setPurchases] = useState<IPurchaseHistoryItem[] | undefined>();
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const getPurchaseHistory = async () => {
            try {
                const purchases = await UserProfileRetriever.getPurchaseHistory();
                const sortedPurchases = R.sortBy(
                    (purchase: IPurchaseView) => -new Date(purchase.timeUtcIso8601).getTime(),
                    purchases);
                const items = sortedPurchases.map(purchase2PurchaseItem);
                setPurchases(items);
            } catch (e) {
                if (e instanceof AuthError) {
                    loginWithRedirect(
                        { appState: { returnTo: window.location.pathname } });
                } else {
                    console.error(e);
                }
            }
        };
        getPurchaseHistory();
    }, [userProfile, loginWithRedirect]);

    const purchase2PurchaseItem = (pur: IPurchaseView): IPurchaseHistoryItem => {
        const purDate = new Date(pur.timeUtcIso8601);
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        const localTime = new Intl.DateTimeFormat(navigator.language, options).format(purDate);

        const amountVal = pur.currency.toLocaleLowerCase() === "usd" ?
            (pur.amountTotal / 100).toFixed(2) :
            pur.amountTotal.toString();
        const amount = amountVal + pur.currency.toLocaleUpperCase();

        const item: IPurchaseHistoryItem = {
            pv: pur,
            localTime,
            amount,
        };
        return item;
    };

    return (
        <Fragment>
            {(purchases && !R.isEmpty(purchases)) &&
                <div className="PurchaseHistory">
                    <h2>Purchase History</h2>
                    <table>
                        <tbody>
                            <tr><th>Time</th><th>Purchase</th><th>Amount</th><th>Tokens Granted</th></tr>
                            {purchases.map(p => (
                                <tr key={p.pv.timeUtcIso8601}>
                                    <td>{p.localTime}</td>
                                    <td>{p.pv.productName}</td>
                                    <td>{p.amount}</td>
                                    <td>{p.pv.tokensGranted}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {(!purchases) &&
                <div className="PurchaseHistory-loading">
                    <Loading />
                </div>
            }
        </Fragment >
    );
}
