import { BuiltInGameId } from "backend-models/services/built-in-games/built-in-games-api-models/BuiltInGameId";
import { IJourneyView } from "backend-models/services/journies/journies-api-models/IJourneyView";
import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import { WebConfig } from "WebConfig";
import { PlayPosOutdatedError } from "PlayPosOutdatedError";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";
import { IAutosaveClearRequest } from "backend-models/app-api-models/IAutosaveClearRequest";
import { SavesRetreiver } from "components/LoadSaves/SavesRetreiver";
import { ILoadSaveRequest } from "backend-models/app-api-models/ILoadSaveRequest";

export class JourneyInitializer {
    public static PlayPosOutdatedImgSrc = "opening_door.webp";

    public static async initByGameId(gameId: string): Promise<IJourneyView> {
        if (WebConfig.IS_OUT_OF_SERVICE === "1") {
            return await this.getNewJourney(gameId);
        }
        const autoSavedJourney = await this.loadAutosavedJourneyIfExists(gameId);
        if (autoSavedJourney) {
            return autoSavedJourney;
        } else {
            return await this.getNewJourney(gameId);
        }
    }

    public static async getNewJourney(gameId: string): Promise<IJourneyView> {
        await Promise.resolve();
        if (gameId === BuiltInGameId.Mithgard35) {
            const newGameJourney: IJourneyView = {
                game: { builtInGameId: BuiltInGameId.Mithgard35, id: BuiltInGameId.Mithgard35 },
                history: []
            };
            return newGameJourney;
        }
        throw Error("Unsupported gameId");
    }

    public static async loadJourney(turnId: string): Promise<IJourneyView> {
        const accessToken = authStore.getState().accessToken;
        const latestSaveable = SavesRetreiver.getLatestSaveable();

        const requestBody: ILoadSaveRequest = {
            latestSavableTurnLoc: latestSaveable?.turnLoc,
        };

        const response = await fetch(
            `/api/v1/user/saves/${turnId}/load`,
            {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when loading a saved game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (response.status === 409) {
            // Play position outdated
            throw new PlayPosOutdatedError(latestSaveable?.turnLoc?.turnId || "none");
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when loading a saved game.");
        }

        const model = await response.json() as IJourneyView;
        return model;
    }

    public static async loadAutosavedJourneyIfExists(gameId: string): Promise<IJourneyView | null> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch(
            `/api/v1/user/autosaves/${gameId}/load`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when loading autosaved game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (response.status === 404) {
            console.log("No Autosave yet available for this game");
            return null;
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when loading autosaved game.");
        }

        const model = await response.json() as IJourneyView;
        return model;
    }

    public static async clearAutosaveIfExists(
        gameId: string, latestSavableTurnLoc: ITurnLocation | undefined
    ) {
        const accessToken = authStore.getState().accessToken;

        const requestBody: IAutosaveClearRequest = {
            latestSavableTurnLoc,
        };
        const response = await fetch(
            `/api/v1/user/autosaves/${gameId}/clear`,
            {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            });
        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when loading autosaved game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (response.status === 409) {
            // Play position outdated
            throw new PlayPosOutdatedError(latestSavableTurnLoc?.turnId || "none");
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when loading autosaved game.");
        }

        console.log("Cleared autosave.");
    }
}
