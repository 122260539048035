import { useState, MouseEvent, KeyboardEvent, useRef, useEffect } from "react";
import "./StatelessButton.css";
import debounce from "lodash.debounce";

interface IProps {
    onPressed: () => void;
    text?: string;
    tip?: string;
    autoFocus?: boolean;
}

export default function StatelessButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);
    const buttonText = props.text ?? "OK";
    const tip = props.tip ?? "";

    const buttonRef = useRef(null);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        props.onPressed();
    }, 500);

    const handleKeyUp = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            debouncedOnPressed();
        }
    };

    useEffect(() => {
        if (props.autoFocus && buttonRef.current) {
            (buttonRef.current as HTMLButtonElement).focus();
        }
    }, [props.autoFocus]);

    return (
        <button
            ref={buttonRef}
            className={
                "StatelessButton" +
                (isDepressed ? " SubmitButton-clicked" : "")
            }
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            onKeyUp={handleKeyUp}
            title={tip}
        >
            {buttonText}
        </button>
    );
};
