import "./Loading.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import FlashingCursor from "components/FlashingCursor/FlashingCursor";
import { ChatViewType } from "components/Chat/ChatViewType";

interface IProps {
    customText?: string;
}

export default function Loading(props: IProps) {
    return (
        <div className="Loading">
            <ChatBubble viewType={ChatViewType.Scene} >
                <ChatPreContainer>
                    <ChatPre viewType={ChatViewType.Scene}>
                        <ChatText text={props.customText || "LOADING"}></ChatText>
                    </ChatPre>
                    <FlashingCursor />
                </ChatPreContainer>
            </ChatBubble>
        </div>
    );
}
