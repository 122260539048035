import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import dayjs from "dayjs";
import { playStore } from "PlayStore";
import { ISavesByGameId } from "backend-models/services/saves/saves-api-models/ISavesByGameId";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";
import { ILatestSavable } from "components/SaveCheckModal/ILatestSavable";

export class SavesRetreiver {

    public static async getSavesForGame(): Promise<ISavesByGameId> {
        const accessToken = authStore.getState().accessToken;
        const game = playStore.getState().game;
        const gameId = (game?.builtInGameId || game?.id) as string;

        const response = await fetch(
            `/api/v1/user/saves?gameId=${gameId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving saved games.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when retrieving saved games.");
        }

        // Deserialize dayjs dates
        const model = await response.json() as ISavesByGameId;
        for (const sbj of model.savesByJourney) {
            for (const save of sbj.saves) {
                save.savedTimeUtc = dayjs(save.savedTimeUtc);
            }
        }

        return model;
    }

    public static getLatestSaveable(): ILatestSavable | null {
        const { latestTurn, history, journeyId } = playStore.getState();
        if (this.getIsSaveable(latestTurn)) {
            const latest: ILatestSavable = {
                turn: latestTurn as ITurnView,
                turnLoc: {
                    journeyId: journeyId as string,
                    historyId: latestTurn?.historyId as string,
                    turnId: latestTurn?.turnId as string,
                },
            };
            return latest;
        }
        for (let i = history.length - 1; i >= 0; i--) {
            if (this.getIsSaveable(history[i])) {
                const nextLatestTurn = history[i];
                const latest: ILatestSavable = {
                    turn: nextLatestTurn,
                    turnLoc: {
                        journeyId: journeyId as string,
                        historyId: nextLatestTurn.historyId as string,
                        turnId: nextLatestTurn.turnId as string,
                    },
                };
                return latest;
            }
        }
        return null;
    };

    private static getIsSaveable(turn: ITurnView | null): boolean {
        if (!turn) {
            return false;
        }
        const scene = turn.scene;
        return scene.isCanon && !scene.problemType;
    }
}
