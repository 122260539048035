import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IPlaySetupState {
    preservedActionText?: string;
    setPreservedActionText: (text: string) => void;
    clearPreservedActionText: () => void;
    isInInsertCoinLoop?: boolean;
    setIsInInsertCoinLoop: (isInInsertCoinLoop: boolean) => void;
    startInsertCointLoopOnRefresh?: boolean;
    setStartInsertCointLoopOnRefresh: (startInsertCointLoopOnRefresh: boolean) => void;
    hasDismissedPulse?: boolean;
    isHelpPulseActive?: boolean;
    triggerHelpPulse: () => void;
    dismissHelpPulse: () => void;
}

export const usePlaySetupStore = create<IPlaySetupState>()(persist((set) => {
    return {
        preservedActionText: undefined,

        setPreservedActionText: (text: string) =>
            set(() => {
                return {
                    preservedActionText: text,
                };
            }),
        clearPreservedActionText: () =>
            set(() => ({
                preservedActionText: undefined,
            })),

        isInInsertCoinLoop: undefined,
        setIsInInsertCoinLoop: (isInInsertCoinLoop: boolean) =>
            set(() => ({
                isInInsertCoinLoop,
            })),

        startInsertCointLoopOnRefresh: undefined,
        setStartInsertCointLoopOnRefresh: (startInsertCointLoopOnRefresh: boolean) =>
            set(() => ({
                startInsertCointLoopOnRefresh,
            })),

        hasDismissedPulse: undefined,
        isHelpPulseActive: undefined,
        triggerHelpPulse: () =>
            set((state) => {
                if (state.hasDismissedPulse) {
                    return {};
                }
                return {
                    isHelpPulseActive: true,
                };
            }),
        dismissHelpPulse: () =>
            set((state) => {
                return {
                    hasDismissedPulse: true,
                    isHelpPulseActive: false,
                };
            }),
    };
}, {
    name: "play-setup-data",
}));

