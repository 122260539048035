import "./DaydreamHelpModal.css";
import Modal from "react-modal";
import { useDaydreamHelpStore } from "./DaydreamHelpStore";
import { useNavigate } from "react-router-dom";
import CloseButton from "components/CloseButton/CloseButton";
import HelpSection from "components/Help/HelpSection/HelpSection";
import DaydreamAnswer from "components/Help/Faq/DaydreamAnswer/DaydreamAnswer";
import ScrollToBottom from "components/ScrollToBottom/ScrollToBottom";

Modal.setAppElement("#root");

export default function InputHelpModal() {
    const { isOpen, closeDaydreamHelp } = useDaydreamHelpStore();
    const navigate = useNavigate();

    const handleCancel = () => {
        closeDaydreamHelp();
    };

    const handleMoreHelp = () => {
        closeDaydreamHelp();
        navigate("/game-manual");
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCancel}
            contentLabel="Daydream Help"
            className="DaydreamHelpModal Modal"
        >
            <div className="Modal-content">
                <CloseButton onPressed={handleCancel} />
                <div className="ScrollableModal-content">
                    <div className="ScrollableModal-inner" id="daydream-help-modal-inner">
                        <p className="ScrollableModal-button-para"><button onClick={handleMoreHelp}>More Help</button></p>
                        <HelpSection>
                            <DaydreamAnswer />
                        </HelpSection>
                        <p className="ScrollableModal-button-para"><button onClick={handleMoreHelp}>More Help</button></p>
                        <ScrollToBottom
                            containerId="daydream-help-modal-inner"
                            isContainerVisible={isOpen}
                            isVisibleUnlessFlushToBottom={true}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
