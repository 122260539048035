import "./ConditionsModal.css";
import Modal from "react-modal";
import { useConditionsModalStore } from "./ConditionsModalStore";
import ContentLink from "components/ContentLink/ContentLink";
import PolicyWarning from "components/Policies/PolicyWarning/PolicyWarning";
import { authStore } from "auth/AuthStore";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import ScrollToBottom from "components/ScrollToBottom/ScrollToBottom";
import SubmitButton, { SubmitButtonStates } from "components/forms/SubmitButton/SubmitButton";
import { useState } from "react";

Modal.setAppElement("#root");

export default function ConditionsModal() {
    const { isOpen, closeModal, onAccepted } = useConditionsModalStore();
    const { setUserProfile } = authStore();
    const { loginWithRedirect } = useAuth0();
    const [submitButtonState, setSubmitButtonState] = useState(SubmitButtonStates.Ready);

    const acceptAndGetUserProfile = async () => {
        try {
            const profile = await UserProfileRetriever.acceptConditionsAndGetUserProfile();
            setUserProfile(profile);
        } catch (e) {
            if (e instanceof AuthError) {
                loginWithRedirect(
                    { appState: { returnTo: window.location.pathname } });
            } else {
                console.error(e);
                throw e;
            }
        }
    };

    const handleAccept = async () => {
        setSubmitButtonState(SubmitButtonStates.Submitting);
        try {
            await acceptAndGetUserProfile();
            setSubmitButtonState(SubmitButtonStates.Submitted);
            closeModal();
            onAccepted();
        } catch {
            setSubmitButtonState(SubmitButtonStates.Error);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            contentLabel="Conditions of use"
            className="ConditionsModal Modal"
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className="Modal-content">
                <div className="ScrollableModal-content">
                    <div className="ScrollableModal-inner" id="conditions-modal-inner">
                        <div className="ConditionsModal-header">
                            <h2>One last thing..</h2>
                        </div>
                        <p>By using EndlessGPT you agree to be bound by the <ContentLink text="Terms of Use" path="/terms-of-use" /> and <ContentLink text="Privacy Policy" path="/privacy-policy" />.</p>
                        <PolicyWarning />
                        <ScrollToBottom
                            containerId="conditions-modal-inner"
                            isContainerVisible={isOpen}
                            isVisibleUnlessFlushToBottom={true}
                        />
                    </div>
                </div>
                <div className="ConditionsModal-button-pane">
                    <SubmitButton
                        text="Accept Terms and Privacy Policy and Submit Action"
                        onPressed={handleAccept}
                        buttonState={submitButtonState}
                        autoFocus={true}
                    />
                </div>
            </div>
        </Modal>
    );
};
