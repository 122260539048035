import { TurnPlayTokenCost } from "backend-models/app-api-models/validation/CommonSchemas";
import { IUserProfile } from "backend-models/services/users/users-api-models/IUserProfile";
import { create } from "zustand";

interface IAuthState {
    accessToken: string | null;
    setToken: (accessToken: string) => void;
    userProfile: IUserProfile | null;
    setUserProfile: (userProfile: IUserProfile) => void;
    setTokenBalance: (updatedBalance: number) => void;
    hasSufficientTokensForTurn: boolean;
    clearAuth: () => void;
}

const getHasSuffientTokensForTurn = (userProfile: IUserProfile) => {
    if (!userProfile) {
        return false;
    }
    return userProfile.tokenBalance >= TurnPlayTokenCost;
};

export const authStore = create<IAuthState>(set => ({
    accessToken: null,
    userProfile: null,
    setToken: (accessToken: string) => set(() => ({ accessToken: accessToken })),
    setUserProfile: (userProfile: IUserProfile) => set(() => {
        const hasSufficientTokensForTurn = getHasSuffientTokensForTurn(userProfile);
        return {
            userProfile: userProfile,
            hasSufficientTokensForTurn,
        };
    }),
    setTokenBalance: (updatedBalance: number) => set((state) => {
        const updatedUserProfile = {
            ...state.userProfile as IUserProfile,
            ...{
                tokenBalance: updatedBalance,
            },
        };
        const hasSufficientTokensForTurn = getHasSuffientTokensForTurn(updatedUserProfile);
        return {
            userProfile: updatedUserProfile,
            hasSufficientTokensForTurn,
        };
    }),
    hasSufficientTokensForTurn: false,
    clearAuth: () => set(() => ({ accessToken: null, userProfile: null })),
}));
