import "./InputHelpModal.css";
import Modal from "react-modal";
import { useInputHelpStore } from "./InputHelpStore";
import { useNavigate } from "react-router-dom";
import CloseButton from "components/CloseButton/CloseButton";
import ScrollToBottom from "components/ScrollToBottom/ScrollToBottom";

Modal.setAppElement("#root");

export default function InputHelpModal() {
    const { isOpen, closeInputHelp } = useInputHelpStore();
    const navigate = useNavigate();

    const handleCancel = () => {
        closeInputHelp();
    };

    const handleMoreHelp = () => {
        closeInputHelp();
        navigate("/game-manual");
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCancel}
            contentLabel="Quick Help"
            className="InputHelpModal Modal"
        >
            <div className="Modal-content">
                <CloseButton onPressed={handleCancel} />
                <div className="ScrollableModal-content">
                    <div className="ScrollableModal-inner" id="input-help-modal-inner">
                        <h2>Quick Help</h2>
                        <p><b>Special Actions (free!)</b></p>
                        <ul className="HelpSection-actions">
                            <li>items</li>
                            <li>purse</li>
                            <li>skills</li>
                        </ul>
                        <p>⚠️ The Special Actions are very important. Be sure to try them!</p>
                        <p><b>Talking</b></p>
                        <p>Start with a single-quote (') to give dialog for your character to speak, e.g:</p>
                        <ul className="HelpSection-actions">
                            <li>'Hello. What is your name?</li>
                        </ul>
                        <p><b>Controls</b></p>
                        <p>Your game is autosaved, but you can create a manual save on any turn, using the <i className="fas fa-save"></i> button.</p>
                        <p>Use the hambuger menu (<img src="hamburger.webp" alt="the hamburger menu appearance"></img>) to check your balance, load saved games and much more.</p>
                        <p><b>Gameplay</b></p>
                        <p>In general, specify an action for your character to perform.</p>
                        <p>That's really it, as long you stick to describing your own actions or dialog, and your action is reasonable. You can't necessarily interact with what's in the pictures, only the text.</p>
                        <p>You can use simple actions, like:</p>
                        <ul className="HelpSection-actions">
                            <li>look around</li>
                            <li>enter shop</li>
                            <li>open chest</li>
                            <li>take sword</li>
                            <li>attack</li>
                        </ul>
                        <p>Or more complex ones, like:</p>
                        <ul className="HelpSection-actions">
                            <li>I use my lightning ring to electrify my longsword and swing for the troll's leg</li>
                            <li>I sprinkle the fairy dust on my cloak</li>
                        </ul>
                        <p className="ScrollableModal-button-para"><button onClick={handleMoreHelp}>More Help</button></p>
                        <ScrollToBottom
                            containerId="input-help-modal-inner"
                            isContainerVisible={isOpen}
                            isVisibleUnlessFlushToBottom={true}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
