import SaveCheckModal from "components/SaveCheckModal/SaveCheckModal";
import "./App.css";
import Sidebar from "components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import InputHelpModal from "components/Help/InputHelpModal/InputHelpModal";
import DaydreamHelpModal from "components/Help/DaydreamHelpModal/DaydreamHelpModal";
import GameOverHelpModal from "components/Help/GameOverHelpModal/GameOverHelpModal";
import CaptchaModal from "components/Captcha/CaptchaModal/CaptchaModal";
import AlertModal from "components/AlertModal/AlertModal";
import ConditionsModal from "components/ConditionsModal/ConditionsModal";

export default function App() {

  return (
    <div className="App" id="outer-container">
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <main className="App-main-container" id="page-wrap">
        <Outlet />
      </main>
      <SaveCheckModal />
      <InputHelpModal />
      <DaydreamHelpModal />
      <GameOverHelpModal />
      <CaptchaModal />
      <ConditionsModal />
      <AlertModal />
    </div>
  );
}
