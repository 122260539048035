import "./ProcessPaymentPage.css";
import Loading from "components/Loading/Loading";
import Page from "components/Page/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { AuthError } from "auth/AuthError";
import { authStore } from "auth/AuthStore";
import { ErrorCopy } from "copy/ErrorCopy";
import { ContactTicketSubmitter } from "components/forms/ContactForm/ContactTicketSubmitter";
import ContentLink from "components/ContentLink/ContentLink";
import StatelessButton from "components/forms/StatelessButton/StatelessButton";
import { usePlaySetupStore } from "components/Chat/PlaySetupStore";

export default function ProcessPaymentPage() {
    const [searchParams] = useSearchParams();
    const checkoutId = searchParams.get("checkoutId") || "";
    const navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();
    const [isFailed, setIsFailed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const { userProfile, setTokenBalance } = authStore();
    const { isInInsertCoinLoop } = usePlaySetupStore();

    if (!checkoutId) {
        navigate("/account");
    }

    const processPayment = async () => {
        try {
            const purchaseStatus = await UserProfileRetriever.getPurchaseStatus(checkoutId);
            if (!purchaseStatus) {
                setIsFailed(true);
            } else {
                setTokenBalance(purchaseStatus.newBalance);
            }
            setIsProcessing(false);
        } catch (e) {
            if (e instanceof AuthError) {
                loginWithRedirect(
                    { appState: { returnTo: window.location.pathname } });
            } else {
                console.error(e);
            }
        }
    };

    const onSupportTicketClick = () => {
        ContactTicketSubmitter.navigateToContactWithPrefill(
            "Problem processing payment",
            `Please look into the processing of my payment, Checkout ID ${checkoutId}, and update me ASAP!`,
            () => { navigate("/contact?purpose=Support"); },
        );
    };

    const playButtonText = isInInsertCoinLoop ?
        "Continue" :
        "Play Now";

    return (
        <Page
            id="process-payment-page"
            needsAuth={true}
            headingIconClass="fa-solid fa-cart-shopping"
            headingText="Processing Payment"
            onLoginConfirmed={processPayment}
        >
            {isProcessing &&
                <Loading customText="PROCESSING" />
            }
            {((!isProcessing) && isFailed) &&
                <Fragment>
                    <p>{ErrorCopy.PaymentStatusUnavailable}</p>
                    <p><ContentLink text="Open Support Ticket" onClick={onSupportTicketClick} /></p>
                </Fragment>
            }
            {((!isProcessing) && !isFailed) &&
                <Fragment>
                    <p>Payment Processed Successfully. Thank you for your support!</p>
                    <p>New Play Token Balance: <span className="ProcessPaymentPage-balance">{userProfile?.tokenBalance}</span></p>
                    <p><StatelessButton text={playButtonText} onPressed={() => navigate("/")} /></p>
                </Fragment>
            }
        </Page>
    );
}
