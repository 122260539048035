import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import { IUserProfile } from "backend-models/services/users/users-api-models/IUserProfile";
import { ErrorCopy } from "copy/ErrorCopy";
import { IStripeSecretResponse } from "backend-models/app-api-models/IStripeSecretResponse";
import { IPurchaseView } from "backend-models/services/payments/payment-api-models/IPurchaseView";
import { IPurchaseStatus } from "backend-models/services/payments/payment-api-models/IPurchaseStatus";

export class UserProfileRetriever {

    public static async getUserProfile(): Promise<IUserProfile> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch("/api/v1/user/profile", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving user profile.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error(ErrorCopy.GetProfileError);
        }

        const model = await response.json() as IUserProfile;
        return model;
    }

    public static async acceptConditionsAndGetUserProfile(): Promise<IUserProfile> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch("/api/v1/user/profile/accept-conditions", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when accepting conditions.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error(ErrorCopy.GetProfileError);
        }

        const model = await response.json() as IUserProfile;
        return model;
    }

    public static async getUserStripeClientSecret(): Promise<string> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch("/api/v1/user/stripe-secret", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving user stripe client secret.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error retrieving stripe client secret");
        }

        const model = await response.json() as IStripeSecretResponse;
        return model.clientSecret;
    }

    public static async getPurchaseStatus(checkoutId: string): Promise<IPurchaseStatus | null> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch(`/api/v1/user/purchases/${checkoutId}/status`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving purchase status.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (response.status === 404) {
            console.warn("Didn't get a purchase status in expected timeframe");
            return null;
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error retrieving purchase status");
        }
        const model = await response.json() as IPurchaseStatus;
        return model;
    }

    public static async getPurchaseHistory(): Promise<IPurchaseView[]> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch("/api/v1/user/purchases", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving purchase history.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error retrieving purchase history");
        }

        const model = await response.json() as IPurchaseView[];
        return model;
    }

}
