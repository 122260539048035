import "./ScrollToBottom.css";
import { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import debounce from "lodash.debounce";

interface IProps {
    containerId: string;
    isContainerVisible: boolean;
    isVisibleUnlessFlushToBottom?: boolean;
}

export default function ScrollToBottom(props: IProps) {
    const [isHidden, setIsHidden] = useState(true);

    const scrollDurationMs = 500;

    const debouncedSetVisibility = debounce((elem: HTMLElement) => {
        // Distance from the top of the container to the top of the scrollable area
        const scrollTop = elem.scrollTop;
        // Visible height of the container
        const containerHeight = elem.clientHeight;
        // Total height of the scrollable content
        const contentHeight = elem.scrollHeight;

        const scrollBottom = contentHeight - scrollTop - containerHeight;

        const flushTolerance = 20;
        const isScrolledToBottom = scrollTop + containerHeight + flushTolerance >= contentHeight;
        const isScrolledUpMoreThanOwnHeight = scrollBottom < containerHeight;

        const isHidden = props.isVisibleUnlessFlushToBottom ?
            isScrolledToBottom :
            isScrolledUpMoreThanOwnHeight;

        setIsHidden(isHidden);
    }, 200);

    const scrollToEnd = () => {
        scroll.scrollToBottom({
            containerId: props.containerId,
            offset: 50,
            duration: scrollDurationMs,
            delay: 10,
            smooth: "linear",
        });
    };

    const debouncedSetVisibilityRef = useRef(debouncedSetVisibility);
    useEffect(() => {
        const containerElem = document.getElementById(props.containerId) as HTMLElement;
        if (!containerElem) {
            return;
        }
        const addedListenerAttr = "data-scroll-listener-added";
        const hasAddedListener = containerElem.hasAttribute(addedListenerAttr);
        if (containerElem && !hasAddedListener) {
            containerElem.setAttribute(addedListenerAttr, "true");
            containerElem.addEventListener("scroll", () => debouncedSetVisibilityRef.current(containerElem));
            debouncedSetVisibilityRef.current(containerElem);
        }
    }, [debouncedSetVisibilityRef, props.containerId, props.isContainerVisible]);

    const hiddenClass = isHidden ? " ScrollToBottom-hidden" : "";

    return (
        <i
            className={`ScrollToBottom${hiddenClass} fa-solid fa-circle-arrow-down`}
            onClick={scrollToEnd}
            title="Scroll to bottom"
        ></i>
    );
}
