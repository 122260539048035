import { create } from "zustand";

interface IAlertModalState {
    isOpen: boolean;
    openAlert: (message: string, imgSrc: string, acknowledgeCallback: () => void) => void;
    closeAlert: () => void;
    onAcknowledge: () => void,
    message: string;
    imgSrc: string;
}

export const useAlertModalStore = create<IAlertModalState>((set) => {

    return {
        isOpen: false,
        openAlert: (message, imgSrc, acknowledgeCallback) =>
            set(() => {
                return {
                    isOpen: true,
                    onAcknowledge: acknowledgeCallback,
                    message,
                    imgSrc,
                };
            }),
        closeAlert: () =>
            set(() => ({
                isOpen: false,
            })),
        onAcknowledge: () => { },
        message: "",
        imgSrc: "",
    };
});

