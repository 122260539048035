import "./LoadableGame.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import LazyPicture from "components/LazyPicture/LazyPicture";
import { ChatViewType } from "components/Chat/ChatViewType";
import LoadButton, { LoadButtonStates } from "../LoadButton/LoadButton";
import { playStore } from "PlayStore";
import { LazyPicSize } from "components/LazyPicture/LazyPicSize";
import { JourneyInitializer } from "JourneyInitializer";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSaveCheckStore } from "components/SaveCheckModal/SaveCheckStore";
import { ISavedGameView } from "backend-models/services/saves/saves-api-models/ISavedGameView";
import { IJourneyView } from "backend-models/services/journies/journies-api-models/IJourneyView";
import { PlayPosOutdatedError } from "PlayPosOutdatedError";
import { useAlertModalStore } from "components/AlertModal/AlertModalStore";
import { ErrorCopy } from "copy/ErrorCopy";

interface IProps {
    save: ISavedGameView,
}

export default function LoadableGame(props: IProps) {
    const navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();
    const { initialize } = playStore();
    const [loadButtonState, setLoadButtonState] = useState(LoadButtonStates.Ready);
    const { performSaveCheck } = useSaveCheckStore();
    const { openAlert } = useAlertModalStore();

    const loadGame = async () => {
        performSaveCheck(

            // Proceed
            async () => {
                try {
                    setLoadButtonState(_ => LoadButtonStates.Loading);
                    let loadedJourney: IJourneyView;
                    try {
                        loadedJourney = await JourneyInitializer.loadJourney(props.save.turnLoc.turnId);
                    } catch (e) {
                        if (e instanceof PlayPosOutdatedError) {
                            console.log("Tried to load a game from an outdated state");
                            openAlert(
                                ErrorCopy.PlayPositionOutdated,
                                JourneyInitializer.PlayPosOutdatedImgSrc,
                                () => {
                                    setTimeout(() => window.location.href = "/", 500);
                                });
                            return;
                        }
                        throw e;
                    }
                    initialize(loadedJourney);
                    setLoadButtonState(_ => LoadButtonStates.Ready);
                    navigate("/");
                } catch (e) {
                    setLoadButtonState(_ => LoadButtonStates.Error);
                    if (e instanceof AuthError) {
                        loginWithRedirect(
                            { appState: { returnTo: window.location.pathname } });
                    } else {
                        console.error(e);
                    }
                }
            },

            // Cancel
            () => { console.log("Aborted loading game"); },
        );
    };

    return (
        <div className="LoadableGame">
            <ChatBubble viewType={ChatViewType.Loadable} onBubbleClicked={loadGame} >
                <div className="LoadableGame-cols">
                    {props.save.picId &&
                        <div className="LoadableGame-pic-col">
                            <LazyPicture pictureId={props.save.picId} size={LazyPicSize.Small} />
                        </div>
                    }
                    <div className="LoadableGame-pre-col">
                        <ChatPreContainer>
                            <ChatPre viewType={ChatViewType.Loadable}>
                                <ChatText
                                    text={props.save.saveName}
                                    subText={props.save.savedTimeUtc.format("YYYY-MM-DD HH:mma")}
                                />
                            </ChatPre>
                        </ChatPreContainer>
                    </div>
                </div>
                <div className="LoadableGame-tray">
                    <LoadButton onPressed={loadGame} buttonState={loadButtonState} />
                </div>
            </ChatBubble>
        </div>
    );
};
