import "./AccountPage.css";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "components/Page/Page";
import { AuthError } from "auth/AuthError";
import { UserProfileRetriever } from "./UserProfileRetriever";
import { authStore } from "auth/AuthStore";
import OneOffPurchaseTable from "components/Purchasing/OneOffPurchaseTable/OneOffPurchaseTable";
import { WebConfig } from "WebConfig";
import PurchaseHistory from "components/Purchasing/PurchaseHistory/PurchaseHistory";
import { Fragment, useState } from "react";
import Loading from "components/Loading/Loading";
import { usePlaySetupStore } from "components/Chat/PlaySetupStore";

export default function AccountPage() {
    const { user, logout, loginWithRedirect } = useAuth0();
    const { userProfile, setUserProfile, clearAuth } = authStore();
    const [isLoading, setIsLoading] = useState(true);
    const { setIsInInsertCoinLoop } = usePlaySetupStore();

    const getUserProfile = async () => {
        try {
            const profile = await UserProfileRetriever.getUserProfile();
            setUserProfile(profile);
            setIsLoading(false);
        } catch (e) {
            if (e instanceof AuthError) {
                loginWithRedirect(
                    { appState: { returnTo: window.location.pathname } });
            } else {
                console.error(e);
            }
        }
    };

    const logoutCompletely = () => {
        clearAuth();
        setIsInInsertCoinLoop(false);
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
        <Page
            id="account-page"
            headingText="Account"
            headingIconClass="fa fa-fw fa-user"
            needsAuth={true}
            onLoginConfirmed={getUserProfile}
            isChildrenLoading={!userProfile}
            hasCloseButton={true}
        >
            {isLoading &&
                <Loading />
            }
            {(!isLoading) &&
                <Fragment>
                    <h2><button onClick={logoutCompletely}>
                        <span className="fa fa-fw fa-sign-out" /><span> Log out</span>
                    </button><span> {(user?.nickname || "").split("@")[0]}</span>
                    </h2>
                    <table>
                        <tbody>
                            <tr><th>Play Token Balance:</th><td className="AccountPage-balance">{userProfile?.tokenBalance}</td></tr>
                        </tbody>
                    </table>
                    {(WebConfig.IS_PAYMENT_DISABLED !== "1") &&
                        <Fragment>
                            <h2 className="AccountPage-currency">All prices are in USD</h2>
                            <OneOffPurchaseTable />
                            <PurchaseHistory />
                        </Fragment>
                    }
                </Fragment>
            }
        </Page>
    );
}
