import { create } from "zustand";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";
import { ILatestSavable } from "./ILatestSavable";
import { SavesRetreiver } from "components/LoadSaves/SavesRetreiver";

interface ISaveCheckState {
    isOpen: boolean;
    gameToSaveFirst: ITurnView | null;
    onProceed: () => void;
    onCancel: () => void;
    performSaveCheck: (
        proceedCallback: (latestSaveable: ILatestSavable | null) => void,
        cancelCallback: () => void) => void;
    closeSaveCheck: () => void;
}

export const useSaveCheckStore = create<ISaveCheckState>((set) => {

    return {
        isOpen: false,
        gameToSaveFirst: null,
        onProceed: () => { },
        onCancel: () => { },
        performSaveCheck: (proceedCallback, cancelCallback) =>
            set(() => {
                const latestSaveable = SavesRetreiver.getLatestSaveable();
                if ((!latestSaveable) || latestSaveable.turn.isSaved) {
                    console.log("Game doesn't need saving.");
                    proceedCallback(latestSaveable);
                    return {};
                }
                return {
                    isOpen: true,
                    gameToSaveFirst: latestSaveable.turn,
                    onProceed: () => { proceedCallback(latestSaveable); },
                    onCancel: cancelCallback,
                };
            }),
        closeSaveCheck: () =>
            set(() => ({
                isOpen: false,
            })),
    };
});

