import "./Page.css";
import { Fragment, ReactNode, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiConfig } from "ApiConfig";
import Loading from "components/Loading/Loading";
import { authStore } from "auth/AuthStore";
import { playStore } from "PlayStore";
import { JourneyInitializer } from "JourneyInitializer";
import { BuiltInGameId } from "backend-models/services/built-in-games/built-in-games-api-models/BuiltInGameId";
import { WebConfig } from "WebConfig";
import CloseButton from "components/CloseButton/CloseButton";
import { useNavigate } from "react-router-dom";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import ScrollToBottom from "components/ScrollToBottom/ScrollToBottom";
import { useSearchParams } from "react-router-dom";

interface IProps {
    children: ReactNode;
    isChildrenLoading?: boolean;
    id: string;
    collapsePadding?: boolean;
    removeTopSpacer?: boolean;
    noScroll?: boolean;
    needsAuth?: boolean;
    onLoginConfirmed?: (accessToken: string) => void;
    headingText?: string;
    headingIconClass?: string;
    hasCloseButton?: boolean;
    hasScrollToBottom?: boolean;
}

export default function Page(props: IProps) {
    const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const { setToken, setUserProfile } = authStore();
    const navigate = useNavigate();
    const useTopSpacer = !(props.removeTopSpacer);
    const [searchParams] = useSearchParams();

    const doesNeedAuth = WebConfig.UNTETHERED === "1" ? false : !!props.needsAuth;

    const { initJourney, initialize } = playStore();

    const hasPermission = !doesNeedAuth || (!isLoading && isAuthenticated);

    const extraClassNames =
        (props.collapsePadding ? "" : " Page-padded") +
        (props.noScroll ? "" : " Page-scrolls");
    const pageClassNames = "Page" + (hasPermission ? extraClassNames : "");

    useEffect(() => {
        // Fix Firefox stalling the login after processing the code for Facebook login
        const code = searchParams.get("code") || "";
        if (code && !isLoading) {
            loginWithRedirect({ appState: { returnTo: window.location.pathname } });
        }
    }, [loginWithRedirect, searchParams, isLoading]);

    const onLoginConfirmedRef = useRef(props.onLoginConfirmed);
    const setTokenRef = useRef(setToken);
    const initializeRef = useRef(initialize);
    useEffect(() => {
        const processLoginStatus = async () => {
            if (WebConfig.UNTETHERED === "1") {
                if (!initJourney) {
                    const initJourney = await JourneyInitializer.initByGameId(BuiltInGameId.Mithgard35);
                    initializeRef.current(initJourney);
                }
                if (!!onLoginConfirmedRef.current) {
                    onLoginConfirmedRef.current("untethered");
                }
            }
            if (isLoading) {
                return;
            }
            if (!isAuthenticated) {
                if (!initJourney) {
                    const newJourney = await JourneyInitializer.getNewJourney(BuiltInGameId.Mithgard35);
                    initializeRef.current(newJourney);
                }
                return;
            }
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: ApiConfig.audience
                    },
                });
                setTokenRef.current(accessToken);
                if (!initJourney) {
                    const newOrExistingJourney = await JourneyInitializer.initByGameId(BuiltInGameId.Mithgard35);
                    const profile = await UserProfileRetriever.getUserProfile();
                    initializeRef.current(newOrExistingJourney);
                    setUserProfile(profile);
                }
                if (!!onLoginConfirmedRef.current) {
                    onLoginConfirmedRef.current(accessToken);
                }
            } catch (error) {
                console.error(error);
            }
        };
        processLoginStatus();
    }, [isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        onLoginConfirmedRef,
        setTokenRef,
        initJourney,
        setUserProfile]);

    return (
        <div id={props.id} className={pageClassNames}>
            {useTopSpacer &&
                <div className="Page-top-spacer"></div>
            }
            {props.hasCloseButton && <CloseButton onPressed={() => navigate("/")} />}
            {(isAuthenticated || !doesNeedAuth) && props.headingText &&
                <h1>{props.headingIconClass && <span className={props.headingIconClass} />} {props.headingText}</h1>
            }
            {((doesNeedAuth && isLoading)
                || ((isAuthenticated || !doesNeedAuth) && props.isChildrenLoading)) &&
                <div className="Page-loading">
                    <Loading />
                </div>
            }
            {(doesNeedAuth && (!isLoading) && (!isAuthenticated)) &&
                <>
                    <p>{props.headingText || "Login"}</p>
                    <button
                        onClick={
                            () => loginWithRedirect({ appState: { returnTo: window.location.pathname } })
                        }
                    >
                        <span className="fa fa-fw fa-sign-in" /><span> Log in</span>
                    </button>
                </>
            }
            {(((!doesNeedAuth) || hasPermission) && initJourney && !props.isChildrenLoading) &&
                <Fragment>
                    {props.children}
                    {props.hasScrollToBottom &&
                        <ScrollToBottom containerId={props.id} isContainerVisible={true} />
                    }
                </Fragment>
            }
        </div>
    );
}
