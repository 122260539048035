import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import NotFound from "components/NoMatch/NotFound";
import AccountPage from "pages/AccountPage/AccountPage";
import Auth0ProviderWithHistory from "auth/Auth0ProviderWithHistory";
import PlayPage from "pages/PlayPage/PlayPage";
import LoadPage from "pages/LoadPage/LoadPage";
import NewGamePage from "pages/NewGamePage/NewGamePage";
import GameManualPage from "pages/GameManualPage/GameManualPage";
import FaqPage from "pages/FaqPage/FaqPage";
import PrivacyPolicyPage from "pages/PrivacyPolicy/PrivacyPolicyPage";
import TermsOfUsePage from "pages/PrivacyPolicy/TermsOfUsePage";
import MailingListVerifyPage from "pages/MailingListVerifyPage/MailingListVerifyPage";
import ContactPage from "pages/ContactPage/ContactPage";
import ProcessPaymentPage from "pages/ProcessPaymentPage/ProcessPaymentPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<PlayPage />} />
            <Route path="account" element={<AccountPage />} />
            <Route path="process-payment" element={<ProcessPaymentPage />} />
            <Route path="new-game" element={<NewGamePage />} />
            <Route path="load" element={<LoadPage />} />
            <Route path="game-manual" element={<GameManualPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="terms-of-use" element={<TermsOfUsePage />} />
            <Route path="mailing-list-verify" element={<MailingListVerifyPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
