import { z } from "zod";

export const TurnPlayTokenCost = 16;

export const MaxTurnChars = 300;

export const EmailMaxChars = 200;
export class CommonSchemas {
    public static EmailSchema = z
        .string()
        .regex(/^[0-9a-zA-Z]+([_.-][0-9a-zA-Z]+){0,6}@[0-9a-zA-Z]+(\.[0-9a-zA-Z]+){0,5}(\.[a-zA-Z]{2,}){1,3}$/)
        .max(EmailMaxChars);

}
