import "./HistoryLoader.css";
import Loading from "components/Loading/Loading";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { MoreHistoryRetriever } from "components/Chat/HistoryLoader/MoreHistoryRetriever";
import { playStore } from "PlayStore";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";

interface IProps {
    isEnabled: boolean;
}

export default function HistoryLoader(props: IProps) {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const loadMoreHistory = async () => {
            const {
                history,
                journeyId,
                prependHistory,
            } = playStore.getState();

            const earliestTurnLoc: ITurnLocation = {
                journeyId: journeyId as string,
                historyId: history[0].historyId,
                turnId: history[0].turnId,
            };
            try {
                const earlierTurns = await MoreHistoryRetriever.loadMoreHistory(earliestTurnLoc);
                prependHistory(earlierTurns);
            } catch (e) {
                if (e instanceof AuthError) {
                    loginWithRedirect({ appState: { returnTo: window.location.pathname } });
                    return;
                }
                console.error(e);
            }
        };
        if (inView && props.isEnabled) {
            console.log("history loader in view");
            loadMoreHistory();
        } else {
            console.log("history loader out of view");
        }
    }, [inView, loginWithRedirect, props.isEnabled]);

    return (
        <div className="HistoryLoader" ref={ref}>
            <Loading />
        </div>
    );
};
