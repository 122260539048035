import "./ChatInput.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ReturnButton from "components/ReturnButton/ReturnButton";
import { useEffect, useState, MouseEvent, KeyboardEvent, useRef } from "react";
import debounce from "lodash.debounce";
import { ChatViewType } from "components/Chat/ChatViewType";
import { chatInputStore } from "components/Chat/ChatInput/ChatInputStore";
import HelpButton from "components/Help/HelpButton/HelpButton";
import { useInputHelpStore } from "components/Help/InputHelpModal/InputHelpStore";
import { usePlaySetupStore } from "components/Chat/PlaySetupStore";
import { WebConfig } from "WebConfig";
import { MaxTurnChars } from "backend-models/app-api-models/validation/CommonSchemas";
import { authStore } from "auth/AuthStore";
import { useConditionsModalStore } from "components/ConditionsModal/ConditionsModalStore";
import { IUserProfile } from "backend-models/services/users/users-api-models/IUserProfile";

interface IProps {
    onSubmit: (text: string) => void;
    onUserFocusingOnInput?: () => void;
    isHidden?: boolean;
    isAutoFocusEnabled: boolean;
}

export default function ChatInput(props: IProps) {
    const { userProfile, hasSufficientTokensForTurn } = authStore();
    const isStuckWithInsufficientTokens =
        WebConfig.IS_PAYMENT_DISABLED === "1"
        && !hasSufficientTokensForTurn;
    const isEnabled = (!isStuckWithInsufficientTokens) && (WebConfig.IS_OUT_OF_SERVICE !== "1" || WebConfig.UNTETHERED === "1");

    const { openInputHelp } = useInputHelpStore();

    const { inputText, setInputText } = chatInputStore();
    const {
        preservedActionText,
        setPreservedActionText,
        clearPreservedActionText,
        isHelpPulseActive,
        dismissHelpPulse,
    } = usePlaySetupStore();

    const initialTextAreaHeight = "18px";
    const [textareaHeight, setTextareaHeight] = useState(initialTextAreaHeight);

    const { promptToAcceptConditionsIfNeeded } = useConditionsModalStore();

    const onTextareaChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(e.target.value);
    };

    const onHelpPressed = () => {
        dismissHelpPulse();
        openInputHelp();
    };

    const debouncedSubmit = debounce(() => {
        submit();
    }, 200);

    const submit = () => {
        if (isEnabled && !props.isHidden) {
            const trimmedText = inputText.trim();
            if (trimmedText === "") {
                return;
            }
            promptToAcceptConditionsIfNeeded(
                userProfile as IUserProfile,
                () => {
                    // Before conditions popup (only if it will open)
                    setPreservedActionText(trimmedText);
                },
                () => {
                    // After conditions accepted or wasn't needed
                    console.log("submitted action");
                    setInputText("");
                    clearPreservedActionText();
                    props.onSubmit(trimmedText);
                });
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            debouncedSubmit();
        }
    };

    const onTextareaClick = (e: MouseEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
        const textarea = e.target as any;
        const value = textarea.value;
        const selectionStart = textarea.selectionStart;
        const selectionEnd = textarea.selectionEnd;
        if (selectionStart === value.length && selectionEnd === value.length) {
            if (!!props.onUserFocusingOnInput) {
                props.onUserFocusingOnInput();
            }
        }
    };

    const textareaRef = useRef(null);

    // Focus when unhidden
    useEffect(() => {
        if (!props.isHidden && textareaRef && textareaRef.current && props.isAutoFocusEnabled) {
            (textareaRef.current as any).focus();
        }
    }, [props.isHidden, props.isAutoFocusEnabled]);

    // Resize when text changes
    const onUserFocusingOnInputRef = useRef(props.onUserFocusingOnInput);
    useEffect(() => {
        if (textareaRef?.current) {
            const textArea = textareaRef.current as HTMLTextAreaElement;
            if (inputText && inputText.length) {
                setTextareaHeight(`${textArea.scrollHeight}px`);
                (textareaRef.current as any).focus();
                if (!!onUserFocusingOnInputRef?.current) {
                    onUserFocusingOnInputRef.current();
                }
            } else {
                setTextareaHeight(initialTextAreaHeight);
            }
        }
    }, [inputText, textareaRef, onUserFocusingOnInputRef]);

    // Restore previously typed action text after a refresh
    const setInputTextRef = useRef(setInputText);
    useEffect(() => {
        if (preservedActionText) {
            setInputTextRef.current(preservedActionText);
        }
    }, [preservedActionText, setInputTextRef]);

    const onFrameClick = () => {
        if (textareaRef?.current) {
            (textareaRef.current as any).focus();
            if (!!props.onUserFocusingOnInput) {
                props.onUserFocusingOnInput();
            }
        }
    };


    return (
        <ChatBubble viewType={ChatViewType.Input} onBubbleClicked={onFrameClick} isHidden={props.isHidden}>
            <div>
                <HelpButton onPressed={onHelpPressed} isPulsing={isHelpPulseActive} />
            </div>
            <ChatPreContainer>
                <ChatPre viewType={"input"}>
                    {isEnabled &&
                        <textarea id="chat-input-textarea"
                            ref={textareaRef}
                            className="ChatInput-textarea"
                            style={{
                                height: textareaHeight,
                            }}
                            value={inputText}
                            onChange={onTextareaChanged}
                            onKeyDown={handleKeyDown}
                            onClick={onTextareaClick}
                            maxLength={MaxTurnChars}
                        ></textarea>
                    }
                    {!isEnabled &&
                        <div className="ChatInput-barricade">
                        </div>
                    }
                </ChatPre>
                <ReturnButton onPressed={debouncedSubmit} />
            </ChatPreContainer>
        </ChatBubble>
    );
}
