import "./AlertModal.css";
import Modal from "react-modal";
import { useAlertModalStore } from "./AlertModalStore";
import StatelessButton from "components/forms/StatelessButton/StatelessButton";

Modal.setAppElement("#root");

export default function AlertModal() {
    const { isOpen, closeAlert, message, imgSrc, onAcknowledge } = useAlertModalStore();

    const handleAcknowledge = async () => {
        closeAlert();
        onAcknowledge();
    };

    return (
        <Modal
            isOpen={isOpen}
            contentLabel="Info"
            className="AlertModal Modal"
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className="Modal-content">
                <div className="AlertModal-header">
                    <h2>{message}</h2>
                </div>
                <div className="AlertModal-img-box">
                    <img src={imgSrc} alt="Representation of modal message"></img>
                </div>
                <div className="AlertModal-button-pane">
                    <StatelessButton text="OK" onPressed={handleAcknowledge} autoFocus={true} />
                </div>
            </div>
        </Modal>
    );
};
