import { useState, MouseEvent } from "react";
import "./HelpButton.css";
import debounce from "lodash.debounce";

interface IProps {
    onPressed: () => void;
    autoMargin?: boolean;
    isPulsing?: boolean;
}

export default function HelpButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        props.onPressed();
    }, 500);

    return (
        <button
            className={`HelpButton${isDepressed ? " HelpButton-clicked" : ""}${props.autoMargin ? " HelpButton-auto-margin" : ""}${props.isPulsing ? " HelpButton-pulse" : ""}`}
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title="Help"
        >
            <i className="fa-regular fa-circle-question"></i>
        </button>
    );
};
